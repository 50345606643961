/*--
/*  4.12 - Winning Awards CSS
/*----------------------------------------*/


// Winning Awards 
.winning-awards{
    background-color: $secondary;
    position: relative;

    & .awards-bg{
        position: absolute;
        top: 0;
        width: 40%;
        height: 100%;
        background-color: rgba($white, 0.01);

        @media #{$laptop-device}{
            width: 35%;
        }

        @media #{$tablet-device, $large-mobile}{
            display: none;
        }
    }
}

// Winning Awards Logo
.winning-awards-logo{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    text-align: center;
    margin-top: -70px;
    padding-left: 70px;

    @media #{$small-mobile}{
        grid-template-columns: 1fr 1fr;
    }

    & .single-awards{
        margin-top: 70px;

        @media #{$large-mobile}{
            margin-top: 50px;
        }
    }

    @media #{$tablet-device, $large-mobile}{
        padding-left: 0;
        margin-top: 0;
    }
}
