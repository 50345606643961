/*--
/*  2.2 - Blockquote CSS
/*----------------------------------------*/

blockquote {
    position: relative;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 0;
    padding-left: 125px;

    @media #{$desktop-device}{
        padding-left: 100px;
    }
    @media #{$large-mobile}{
        padding-left: 80px;
    }
    @media #{$small-mobile}{
        padding-left: 0;
    }
    
    & .quote{
        font-family: $headings-font-family;
        color: $primary;
        font-size: 185px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        line-height: 0.3;

        @media #{$small-mobile}{
            position: relative;
            top: 0;
            transform: translateY(0);
        }

        & sub{
            bottom: -48px;
            font-size: 185px;
            line-height: 0.3;
        }
    }

    & p {
        font-size: 23px;
        font-family: $headings-font-family;
        font-weight: 500;
        line-height: 1.43 !important;
        color: $secondary;

        @media #{$desktop-device, $large-mobile}{
            font-size: 20px;
        }
    }
}