/*--
/*  4.17 - Contact CSS
/*----------------------------------------*/

// Contact Map 
.contact-map{

    & iframe{
        width: 100%;
        height: 800px;
        display: block;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            height: 600px;
        }
        @media #{$small-mobile}{
            height: 400px;
        }
    }
}



// Contact Wrapper 
.contact-wrapper{
    background-color: #f8f8f8;
    padding: 100px;
    padding-top: 90px;

    @media #{$desktop-device, $large-mobile}{
        padding: 70px;
        padding-top: 60px;
    }

    @media #{$small-mobile}{
        padding: 30px;
        padding-top: 25px;
    }
}


// Contact Title 
.contact-title{
    & .title{
        font-size: 45px;
        margin-bottom: 0;
        color: $secondary;

        @media #{$large-mobile}{
            
        }

        @media #{$small-mobile}{
            font-size: 30px;
        }
    }
    & p{
        margin-top: 15px;

        @media #{$large-mobile}{
            & br{
                display: none;
            }
        }
    }
}


// Contact Form 
.contact-form{
    padding-top: 10px;

    & .single-form{
        & input:not([type="checkbox"]):not([type="radio"]),
        & textarea{
            height: 60px;
            border: 1px solid $white;
            background-color: $white;
            transition: $transition-base;

            &:focus{
                outline: none;
                border-color: $primary;
            }
        }
        & textarea{
            height: 330px;
        }

        & .btn{
            height: 60px;
            line-height: 58px;
            padding: 0 50px;
            font-size: 15px;

            &::before{
                width: 300px;
                height: 300px;
            }
        }
    }
}



// Form Message 
.form-message{
    margin-bottom: 0;

    &.error,
    &.success {
        font-size:16px;
        color:#07294d;
        background:#ddd;
        padding:10px 15px;
        margin-top:15px;
    }
    &.error {
        color:red
    }
}



// Contact Info 
.content-info{
    padding-top: 30px;
}


// Single Contact Info 
.single-content-info{
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 25px 40px;
    margin-top: 30px;
    transition: $transition-base;

    @media #{$desktop-device, $small-mobile}{
        padding: 25px 20px;
    }

    & .info-icon{

    }
    & .info-content{
        flex: 1;
        padding-left: 40px;

        @media #{$desktop-device, $small-mobile}{
            padding-left: 20px;
        }

        & .title{
            font-family: $font-family-base;
            font-weight: 400;
            font-size: 16px;
            color: $secondary;
            transition: $transition-base;
        }
        & p{
            color: $secondary;
            font-weight: 700;
            transition: $transition-base;

            & a{
                transition: $transition-base;
            }
        }
    }

    &:hover{
        background-color: $secondary;

        & .info-content{
            & .title{
                color: $white;
            }
            & p{
                color: $white;
            }
        }        
    }
}



