/*--
/*  4.16 - FAQ CSS
/*----------------------------------------*/

// FAQ Search
.faq-search{
    background-color: #f8f8f8;
    padding: 65px 100px;
    margin-top: 80px;

    @media #{$tablet-device}{
        padding: 45px 70px;
        margin-top: 60px;
    }
    @media #{$large-mobile}{
        padding: 25px 40px;
        margin-top: 40px;
    }
    @media #{$small-mobile}{
        padding: 20px;
    }

    & .search-form{
        position: relative;

        & input{
            width: 100%;
            height: 64px;
            border: 1px solid #f2f2f2;
            font-size: 15px;
            padding: 0 30px;
            padding-right: 230px;
            border-radius: 10px;
            color: $body-color;
            transition: $transition-base;

            @media #{$large-mobile}{
                padding-right: 190px;
            }
            @media #{$small-mobile}{
                padding: 0 20px;
            }

            &:focus{
                outline: none;
                border-color: $primary;
            }

            @include placeholder{
                opacity: 1;
            }
        }
        & button{
            padding: 0 45px;
            height: 56px;
            border-radius: 10px;
            background-color: $primary;
            color: $white;
            border: 0;
            position: absolute;
            top: 4px;
            right: 4px;
            font-weight: 500;

            @media #{$large-mobile}{
                padding: 0 25px;
            }
            @media #{$small-mobile}{
                position: relative;
                top: 0;
                right: 0;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}


// FAQ Wrapper
.faq-wrapper{
    padding-top: 50px;

    @media #{$tablet-device}{
        padding-top: 30px;
    }
    @media #{$large-mobile}{
        padding-top: 10px;
    }
}


// FAQ Images 
.faq-images{
    margin-top: 50px;

    @media #{$extraBig-device, $laptop-device}{
        padding-right: 145px;
    }

    & img{
        width: 100%;
    }
}


// FAQ Main Content 
.faq-main-content{
    padding-top: 20px;
}


// Single FAQ 
.single-faq{
    display: flex;
    border-bottom: 1px solid #ededed;
    padding: 30px 0 20px;

    & .faq-number{
        & .number{
            width: 60px;
            height: 60px;
            line-height: 60px;
            display: block;
            background-color: #f8f8f8;
            font-size: 15px;
            font-weight: 500;
            color: $secondary;
            text-align: center;
            border-radius: 50%;
            transition: $transition-base;
            
            @media #{$desktop-device, $small-mobile}{
                width: 45px;
                height: 45px;
                line-height: 45px;
            }
        }
    }
    & .faq-content{
        flex: 1;
        padding-left: 30px;

        & .title{
            margin-bottom: 0;
            font-size: 30px;
            color: $secondary;
            transition: $transition-base;

            @media #{$desktop-device, $small-mobile}{
                font-size: 24px;
            }
        }
        & p{
            margin-top: 15px;
            margin-bottom: 0;
            line-height: 1.8;
            font-size: 15px;
        }

    }

    &:hover{
        & .faq-number{
            & .number{
                background-color: $primary;
                color: $white;
            }
        }
        & .faq-content{    
            & .title{
                color: $primary;
            }    
        }
    }
}






