/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

/*-- Google Font --*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'Mermaid';
    src: url('../fonts/Mermaid-Bold.eot');
    src: url('../fonts/Mermaid-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mermaid-Bold.woff2') format('woff2'),
        url('../fonts/Mermaid-Bold.woff') format('woff'),
        url('../fonts/Mermaid-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/*-- Common Style --*/
*,
*::after,
*::before {
    box-sizing: border-box;
}
html,
body {
    height: 100%;
}
body {
    position: relative;
    outline: none;
    visibility: visible;
    overflow-X: hidden;

    color: "";
    &[dir="rtl"] {
        text-align: right;
    }
}



a,
button {
    transition: $transition-base;
    outline: 0;

    &:focus{
        outline: 0;
    }
}

a{
    text-decoration: none;
    color: inherit;
}

p{
    font-size: 15px;
    line-height: 1.8;
}

p:last-child{
    margin-bottom: 0;
}

ul,ol{
    padding: 0;
    list-style: none;
    margin: 0;
}

img{
    max-width: 100%;
}

/* Section Style */
.section,
.main-wrapper {
    float: left;
    width: 100%;
}
.main-wrapper{
    overflow: hidden;
}


/* Section Padding */
.custom-container{
    max-width: 1660px;
}


/* Section Padding */
.section-padding{
    padding-top: 150px;
    padding-bottom: 150px;

    @media #{$laptop-device}{
        padding-top: 120px;
        padding-bottom: 120px;
    }
    @media #{$desktop-device}{
        padding-top: 100px;
        padding-bottom: 100px;
    }
    @media #{$tablet-device}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
}

.section-padding-02{
    padding-top: 150px;

    @media #{$laptop-device}{
        padding-top: 120px;
    }
    @media #{$desktop-device}{
        padding-top: 100px;
    }
    @media #{$tablet-device}{
        padding-top: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 60px;
    }
}


/* Section Title */
.section-title{
    & .sub-title{
        font-size: 15px;
        font-weight: 600;
        font-family: $font-family-base;
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 0;
        letter-spacing: 3px;

        @media #{$small-mobile}{
            font-size: 14px;
            letter-spacing: 2px;
        }
    }
    & .title{
        font-size: 60px;
        margin-bottom: 0;
        color: $secondary;
        margin-top: 35px;

        @media #{$desktop-device, $tablet-device}{
            font-size: 50px;
        }
        @media #{$large-mobile}{
            font-size: 40px;
            margin-top: 20px;
        }
        @media #{$small-mobile}{
            font-size: 30px;
        }
    }
    & p{
        margin-top: 20px;
    }

    &.section-title-white{
        & .title{
            color: $white;
        }
    }
}


/* Overlay */
.overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    @include transition(all 0.3s linear);

    &.open{
        opacity: 0.7;
        visibility: visible;
    }
}


// Page Pagination
.page-pagination{
    padding-top: 80px;
    
    @media #{$large-mobile}{
        padding-top: 60px;
    }

    & .pagination{
        & li{
            margin-right: 12px;

            &:last-child{
                margin-right: 0;
            }

            & a{
                width: 50px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 15px;
                font-weight: 600;
                color: $secondary;
                background-color: #f8f8f8;
                display: block;
                transform: $transition-base;

                &.active,
                &:hover{
                    color: $white;
                    background-color: $primary;
                }
            }
        }
    }
}
