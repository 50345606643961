/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/

.footer-section{}


.footer-bg{
    & img{
        width: 100%;
    }
}

// Footer Copyright
.copyright{
    background-color: $secondary;
}
.copyright-text{
    text-align: center;
    padding: 20px 0;

    & p{
        color: #c4c4c4;
        font-size: 16px;
        font-weight: 400;

        & span{
            color: $primary;
            text-transform: uppercase;
        }

        & i{
            color: $primary;
        }

        & a{
            color: $white;
            transition: $transition-base;
            font-weight: 500;

            &:hover{
                color: $primary;
            }
        }
    }
}


// Back to Top
.back-to-top{
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 18px;
    width: 40px;
    height: 40px;
    line-height: 36px;
    border-radius: 50%;
    z-index: 99;
    text-align: center;
    display: none;
    box-shadow: 0px 0px 30px 0px rgba(156,156,156,0.25);    
    transition: all 0.3s linear;
    color: $white;
    background-color: $dark;    

    &:hover{
        color: $white;
        background-color: $primary;
    }
}

