/*--
/*  5.1 - Footer Widget CSS
/*----------------------------------------*/

// Footer Widget Section 
.footer-widget-section{}

// Footer Widget Title
.footer-widget-title{
    color: $secondary;
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 0px;
    padding-bottom: 10px;
}

// Footer Widget 
.footer-widget{
    margin-top: 50px;

    & p{
        margin-bottom: 0;
        margin-top: 30px;
        line-height: 2.3;
    }

    & .widget-call{
        margin-top: 30px;

        & .title{
            font-family: $font-family-base;
            font-weight: 500;
            font-size: 15px;
            color: $primary;
            transition: $transition-base;
            margin-bottom: 0;
        }
        & p{
            margin-top: 12px;
            line-height: 1.2;

            & a{
                font-weight: 500;
                font-size: 22px;
                color: $secondary;

                &:hover{
                    color: $primary;
                }
            }
        }
    }    

    & .footer-link{
        padding-top: 15px;
        
        & li{
            margin-top: 25px;

            & a{
                font-size: 16px;
                color: $body-color;
                transition: $transition-base;

                &:hover{
                    color: $primary;
                }
            }
        }
    }

    & .widget-twitter-feeds{
        padding-top: 10px;
        
        & .twitter-feeds{
            margin-top: 30px;

            & .title{
                font-family: $font-family-base;
                font-weight: 500;
                font-size: 15px;
                color: $primary;
            }
            & p{
                margin-top: 0;
                font-size: 15px;
                line-height: 1.5;

                @media #{$desktop-device}{
                    font-size: 13px;
                }
            }
        }
    }
    
    
}

// Footer Widget Link 
.footer-widget-link{
    display: flex;
    justify-content: space-around;

    @media #{$tablet-device, $large-mobile}{
        justify-content: flex-end;
        flex-wrap: wrap;
    }

    & .footer-widget{
        @media #{$tablet-device, $large-mobile}{
            width: 50%;
        }
        @media #{$small-mobile}{
            width: 100%;
        }
    }
}

