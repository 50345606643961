/*--
/*  4.18 - 404 Error CSS
/*----------------------------------------*/

.error-page{
    & img{
        width: 100%;
    }

    & .error-content{
        padding-top: 120px;

        @media #{$desktop-device}{
            padding-top: 90px;
        }
        @media #{$tablet-device}{
            padding-top: 70px;
        }
        @media #{$large-mobile}{
            padding-top: 50px;
        }

        & .title{
            font-size: 45px;
            margin-bottom: 0;
            color: $secondary;

            @media #{$large-mobile}{
                font-size: 30px;
            }
            @media #{$small-mobile}{
                font-size: 24px;
            }
        }
        & .btn{
            margin-top: 45px;
            height: 60px;
            line-height: 58px;
            padding: 0 45px;
            font-size: 15px;

            @media #{$small-mobile}{
                height: 50px;
                line-height: 48px;
                padding: 0 30px;
            }

            &::before{
                width: 300px;
                height: 300px;
            }
        }
    }
}






