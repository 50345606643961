/*--
/*  4.6 - Testimonial CSS
/*----------------------------------------*/

// Testimonial Section 
.testimonial-section{
    background-color: #f8f8f8;
    position: relative;
    overflow: hidden;
    z-index: 1;

    & .testimonial-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center bottom;
        opacity: 0.05;
        z-index: -1;
    }
}


// Testimonial Wrapper
.testimonial-wraaper{
    margin-top: 93px;
    position: relative;

    @media #{$tablet-device, $large-mobile}{
        margin-top: 5px;
        display: flex;
        flex-wrap: wrap;
    }
    @media #{$tablet-device}{
        margin-top: 33px;
    }

    & .testimonial-01{
        position: absolute;
        top: 120px;
        left: 30px;

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             top: 0;
             left: 0;
         }
    }
    & .testimonial-02{
        position: absolute;
        top: 65px;
        left: 33.5%;

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             top: 0;
             left: 0;
         }
    }
    & .testimonial-03{
        position: absolute;
        bottom: 90px;
        left: 23%;

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             bottom: 0;
             left: 0;
         }
    }
    & .testimonial-04{
        position: absolute;
        top: 40px;
        left: 68.5%;

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             top: 0;
             left: 0;
         }
    }
    & .testimonial-05{
        position: absolute;
        top: 47%;
        left: 61.8%;

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             top: 0;
             left: 0;
         }
    }
    & .testimonial-06{
        position: absolute;
        top: 60%;
        left: 86.3%;

        @media #{$desktop-device}{
            top: 73%;
            left: 75.3%;
        }

        @media #{$tablet-device, $large-mobile}{
             position: relative;
             top: 0;
             left: 0;
         }
    }
}


// Single Testimonial
.single-testimonial{
    position: relative;
    display: inline-block;

    @media #{$tablet-device, $large-mobile}{
        padding: 0 10px;
        width: 50%;
        margin-top: 30px;
    }
    @media #{$small-mobile}{
        width: 100%;
    }

    & .testimonial-author{
        & img{
            border-radius: 50%;
            border: 3px solid $white;
            cursor: pointer;
        }
    }
    & .testimonial-content{
        width: 250px;
        border-radius: 10px;
        border-top-left-radius: 0;
        background-color: $white;
        padding: 15px 30px;
        margin-top: 20px;
        opacity: 0;
        visibility: hidden;     
        position: absolute;
        top: 100%;
        left: 0;
        transition: $transition-base;
        z-index: 3;

        @media #{$tablet-device, $large-mobile}{
            opacity: 1;
            visibility: visible;
            position: relative;
            top: 0;
            padding: 10px 20px;
            width: 100%;
        }
          
        &::before{
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-bottom: 25px  solid $white;
            border-right: 40px solid transparent;
            top: -25px;
            left: 0;
        }

        & p{
            font-size: 13px;
            margin-bottom: 0;
        }

        & .name{
            font-family: $font-family-base;
            font-size: 15px;
            font-weight: 700;
            color: $secondary;
            margin-top: 10px;

            & span{
                color: #737679;
                font-weight: 400;
                font-size: 13px;

                &::before{
                    content: '/';
                    margin: 0 5px;
                }
            }
        }
    }

    &:hover{
        & .testimonial-content{
            opacity: 1;
            visibility: visible;
        }
    }
}











