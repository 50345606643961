/*--
/*  4.10 - Page Banner CSS
/*----------------------------------------*/

// Page Banner 
.page-banner{
    height: 690px;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media #{$desktop-device}{
        height: 520px;
    }
    @media #{$tablet-device}{
        height: 490px;
    }
    @media #{$large-mobile}{
        height: 420px;
    }

    @media #{$small-mobile}{
        height: 340px;
    }

    &::before{
        background-color: rgba($secondary, 0.5);
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-top: 110px solid $white;
        border-left: 130px solid $white;
        border-bottom: 130px solid $white;
        opacity: 0.85;

        @media #{$laptop-device}{            
            border-left-width: 70px;
            border-bottom-width: 70px;
        }
        @media #{$desktop-device}{
            border-left-width: 50px;
            border-bottom-width: 50px;
            border-top-width: 98px;
        }
        @media #{$tablet-device}{
            border-left-width: 30px;
            border-bottom-width: 30px;
            border-top-width: 96px;
        }
        @media #{$large-mobile}{
            border-top-width: 90px;
            border-left-width: 30px;
            border-bottom-width: 30px;
        }
        @media #{$small-mobile}{
            border-left-width: 0;
            border-top-width: 76px;
        }
    }
}


// Page Banner Content 
.page-banner-content{

    @media #{$tablet-device}{
        padding-top: 80px;
    }
    @media #{$large-mobile}{
        padding-top: 55px;
    }

    & .title{
        color: $white;
        font-size: 70px;
        font-weight: 700;

        @media #{$tablet-device}{
            font-size: 60px;
        }
        @media #{$large-mobile}{
            font-size: 46px;
        }
        @media #{$small-mobile}{
            font-size: 40px;
        }
    }
   
    
    & .breadcrumb {
        margin: 0;
        padding: 0;

        & li{
            color: $primary;
            font-size: 16px;
            font-weight: 500;
            position: relative;

            @media #{$small-mobile}{
                font-size: 14px;
            }

            & + li{
                &::before{
                    content: "||";
                    color: $white;
                    margin: 0 10px;
                }
            }

            & a{
                color: $white;

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}









