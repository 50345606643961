/*--
/*  3.1 - Header Main CSS
/*----------------------------------------*/

// Header Section
.header-section{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    & .header-bg-1{
        @media #{$extraBig-device}{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            right: calc((100% - -1600px) / 2);
            background-color: $secondary;
        }
    }

    & .header-bg-2{
        @media #{$extraBig-device}{
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            left: calc((100% - -1400px) / 2);
            background-color: $white;
        }
    }
}

/* Header Container */
.header-container{
    max-width: 1920px;
    margin: 0 auto;
}

// Header Wrapper
.header-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;   
}


// Header Logo
.header-logo{
    background-color: $secondary;
    padding: 27px 40px;
    position: relative;

    @media #{$laptop-device, $large-mobile}{
        padding: 27px 30px;
    }
    @media #{$desktop-device}{
        padding: 27px 15px;
    }
    @media #{$tablet-device}{
        padding: 20px 40px;
    }
    @media #{$small-mobile}{
        padding: 20px 15px;
    }
    

    &::before{
        position: absolute;
        content: '';
    }

    & img{
        @media #{$desktop-device}{
            width: 170px;
        }
        @media #{$large-mobile}{
            width: 140px;
        }
    }
}


// Header Menu
.header-menu{
    & ul{
        display: flex;

        & li{
            position: relative;
            padding: 41px 0;

            @media #{$desktop-device}{
                padding: 37px 0;
            }

            & + li{
                margin-left: 70px;

                @media #{$laptop-device}{
                    margin-left: 40px;
                }
                @media #{$desktop-device}{
                    margin-left: 30px;
                }
            }
            & a{
                font-size: 18px;
                font-weight: 500;
                color: $secondary;
                transition: $transition-base;
                display: block;

                @media #{$laptop-device, $desktop-device}{
                    font-size: 16px;
                }
            }

            &.active,
            &:hover{
                & > a{
                    color: $primary;
                }
            }

            & ul{
                display: block;

                & li{
                    margin-left: 0;
                    padding: 0;
                }
            }

            & .sub-menu{
                position: absolute;
                width: 215px;
                background-color: $white;
                border-top: 2px solid $secondary;
                padding: 10px 0px;
                box-shadow: 2px 2px 20px rgba($primary, 0.1);
                top: 110%;
                left: 0;
                z-index: 99;
                transition: $transition-base;
                opacity: 0;
                visibility: hidden;

                & li{
                    & a{
                        font-size: 14px;
                        padding: 7px 20px;
                    }

                    & .sub-menu{
                        top: 10px;
                        left: 100%;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &:hover{
                        & .sub-menu{
                            top: -10px;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }

            &:hover{
                & .sub-menu{
                    top: 100%;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}


// Header Language Button
.header-language-btn{
    display: flex;
    align-items: center;
    padding: 29px 100px;
    background-color: $white;

    @media #{$laptop-device}{
        padding: 29px 40px;
    }
    @media #{$desktop-device}{
        padding: 23px 15px;
    }

    & .header-language{
        & .dropdown-toggle{
            border: 0;
            background: none;
            padding: 0;
            font-size: 18px;
            font-weight: 500;
            color: $secondary;

            &::after{
                width: 7px;
                height: 7px;
                border-left: 1px solid $secondary;
                border-bottom: 1px solid $secondary;
                border-right: 0;
                border-top: 0;
                transform: rotate(-45deg);
                margin-left: 8px;
            }
        }
        & .dropdown-menu{
            border: 0;
            padding: 0;
            box-shadow: 1px 1px 5px 0 rgba($black, 0.2);
            background: $white;
            padding: 8px;                    
            width: 150px;
            min-width: inherit;
            border-radius: 0;
            top: 37px !important;   
            transform: translate3d(0px, 0px, 0px) !important;

            & > li{
                & a{
                    color: $secondary;
                    text-transform: capitalize;
                    text-decoration: none;
                    font-size: 14px;
                    padding: 0 10px;
                    line-height: 30px;
                    transition: $transition-base;
                    display: block;

                    &:hover{
                        color: $primary;
                    }
                }
            }                
        } 
    }
    & .header-btn{
        margin-left: 30px;

        & .btn{
            width: 200px;

            @media #{$desktop-device}{
                width: 160px;
            }

            &::before{
                width: 300px;
                height: 300px;
            }
        }
    }
}


// Header Mobile Toggle
.header-mobile-toggle{
    background-color: $white;
    padding: 35px 50px;

    & a{
        display: block;
        
        & span{
            width: 25px;
            height: 2px;
            background-color: $secondary;
            display: block;
            margin: 5px 0;
        }
    }
    @media #{$large-mobile}{
        padding: 32px 50px;
    }
    @media #{$small-mobile}{
        padding: 25px 30px;
    }
}

// Header Sticky 
.sticky{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    // animation: sticky 1s;
    box-shadow: 2px 4px 8px rgba($dark, 0.15);
    background-color: $white;    
    padding:  0;
}
@-webkit-keyframes sticky {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}

@keyframes sticky {
    0%{
        transform: translateY(-100%)
    }
    100%{
        transform: translateY(0%)
    }
}



