/*-----------------------------------------------------------------------------------

    Template Name: Tourve - Traveling Agency Bootstrap 5 Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Component CSS
        2.1 - Button CSS
        2.2 - Blockquote CSS
        2.3 - Form CSS
        2.4 - Search CSS
    03. Header CSS
        3.1 - Header Main CSS
        3.2 - Mobile Menu CSS
    04. Page CSS
        4.1 - Slider CSS
        4.2 - Booking Form CSS
        4.3 - Package CSS
        4.4 - Brand CSS
        4.5 - Why Choose CSS
        4.6 - Testimonial CSS
        4.7 - Destination CSS
        4.8 - Blog CSS
        4.9 - Newsletter CSS
        4.10 - Page Banner CSS
        4.11 - Mission And Vision CSS
        4.12 - Winning Awards CSS
        4.13 - Team CSS
        4.14 - Packages Details CSS
        4.15 - Blog Details CSS
        4.16 - FAQ CSS
        4.17 - Contact CSS
        4.18 - 404 Error CSS
    05. Widget CSS
        5.1 - Footer Widget CSS
        5.2 - Sidebar Widget CSS
    06. Footer CSS

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variables";
@import "./bootstrap/bootstrap";

@import "common";
@import "component";
@import "header";
@import "page";
@import "widget";
@import "footer";

