/*--
/*  4.14 - Packages Details CSS
/*----------------------------------------*/


// Package Details Image 
.package-details-images{
    & .swiper-slide{
        & img{
            width: 100%;
        }
    }

    & .swiper-pagination {
        position: relative;
        margin-top: 40px;

        & .swiper-pagination-bullet{
            opacity: 1;
            background-color: $secondary;
            outline: none;

            &.swiper-pagination-bullet-active{
                background-color: $primary;
            }
        }
    }
}


// Package Details Content 
.package-details-content{
    margin-top: 50px;

    & .title{
        font-size: 35px;
        color: $secondary;
        margin-bottom: 0;

        @media #{$large-mobile}{
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 26px;
        }
    }

    & p{
        margin-bottom: 0;
        margin-top: 20px;
        line-height: 1.7;
    }

    & .details-title{
        display: flex;
        padding-bottom: 50px;        

        @media #{$small-mobile}{
            display: block;
        }

        & .price-box{
            background-color: $primary;
            text-align: center;
            width: 80px;
            padding: 13px 0;
            height: max-content;

            & p{
                color: $white;
                font-size: 15px;
                margin-bottom: 0;
                margin-top: 0;
            }
            & .price{
                font-size: 25px;
                font-family: $headings-font-family;
                color: $white;
            }
        }
        & .details-meta-title{
            flex: 1;
            padding-left: 40px;

            @media #{$desktop-device}{
                padding-left: 25px;
            }
            @media #{$small-mobile}{
                padding-left: 0;
                padding-top: 25px;
            }

            & .meta{
                margin-bottom: 15px;

                & span{
                    font-size: 15px;
                    color: #737679;
                    margin-right: 40px;
                    margin-bottom: 10px;
                    display: inline-block;

                    @media #{$desktop-device}{
                        margin-right: 15px;
                    }
                    @media #{$small-mobile}{
                        margin-right: 15px;
                        font-size: 14px;
                    }

                    &:last-child{
                        margin-right: 0;
                    }

                    & i{
                        color: $primary;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    & .details-tags-share{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 15px;
        padding-top: 5px;

        @media #{$small-mobile}{
            display: block;
        }

        & .label{
            font-size: 15px;
            font-weight: 600;
            color: $secondary;
            margin-right: 10px;
        }
        & .details-tags{
            display: flex;
            margin-top: 10px;

            & .tags{
                display: flex;

                & li{
                    margin-right: 3px;

                    & a{
                        color: $body-color;
                        font-size: 15px;

                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
        & .details-share{
            display: flex;
            align-items: center;
            margin-top: 10px;

            & .social{
                display: flex;

                & li{                    
                    & + li{
                        margin-left: 10px;
                    }
                    & a{
                        width: 40px;
                        height: 40px;
                        line-height: 38px;
                        text-align: center;
                        border: 1px solid rgba(70, 86, 105, 0.15);
                        font-size: 16px;
                        display: inline-block;
                        color: $secondary;

                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    & .details-content{
        margin-top: 43px;        
    }

    & .package-details-items{
        & .details-item{
            display: flex;
            align-items: center;
            background-color: #f8f8f8;
            padding: 20px 25px;
            margin-top: 30px;

            @media #{$large-mobile}{
                padding: 15px 20px;
            }

            & .item-icon{
                & i{
                    width: 57px;
                    height: 57px;
                    line-height: 57px;
                    text-align: center;
                    background-color: $primary;
                    color: $white;
                    display: inline-block;
                    font-size: 26px;
                    border-radius: 50%;

                    @media #{$large-mobile}{
                        width: 45px;
                        height: 45px;
                        line-height: 45px;
                        font-size: 24px;
                    }
                }
            }
            & .item-content{
                flex: 1;
                padding-left: 20px;

                & .item-title{
                    font-size: 20px;
                    font-family: $font-family-base;
                    font-weight: 600;
                    color: $secondary;

                    @media #{$large-mobile}{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    
    & .details-day{
        padding: 0 30px;

        @media #{$large-mobile}{
            padding: 0;
        }

        & .single-day{
            display: flex;
            margin-top: 30px;

            & .day-icon{
                & i{
                    width: 57px;
                    height: 57px;
                    line-height: 57px;
                    text-align: center;
                    background-color: #f8f8f8;
                    color: $primary;                    
                    display: inline-block;
                    font-size: 18px;
                    border-radius: 50%;
                    transition: $transition-base;

                    @media #{$small-mobile}{
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 16px;
                    }
                }
            }
            & .day-content{
                padding-left: 30px;
                flex: 1;

                @media #{$small-mobile}{
                    padding-left: 15px;
                }

                & .day-title{
                    font-size: 25px;
                    color: $secondary;
                    margin-bottom: 0;

                    @media #{$small-mobile}{
                        font-size: 22px;
                    }
                }
            }

            &:hover{
                & .day-icon{
                    & i{
                        background-color: $primary;
                        color: $white;
                    }
                }
            }            
        }
    }
    
    & .details-review{
        margin-top: 35px;
    }

    & .review-wrapper{
        padding-top: 20px;
    }

    & .single-review{
        background-color: #f8f8f8;
        padding: 35px 50px;
        display: flex;
        margin-top: 20px;

        @media #{$desktop-device, $large-mobile}{
            padding: 25px 30px;
        }
        @media #{$small-mobile}{
            display: block;
        }

        & .review-images{}
        & .review-content{
            position: relative;
            flex: 1;
            padding-left: 40px;

            @media #{$desktop-device, $large-mobile}{
                padding-left: 30px;
            }
            @media #{$small-mobile}{
                padding-left: 0;
                padding-top: 25px;
            }
            
            & .name{
                font-size: 25px;
                color: $secondary;
                margin-bottom: 0;
            }
            & .designation{
                color: $primary;
                font-size: 13px;
                display: block;
                margin-top: 5px;
            }
            & .review-rating{
                position: absolute;
                top: 0;
                right: 0;
                display: inline-block;

                @media #{$small-mobile}{
                    position: relative;
                    margin-top: 5px;
                }

                &::before{
                    content: '\f000 \f000 \f000 \f000 \f000';
                    font-family: IcoFont;
                    color: #c4beb7;
                    font-size: 14px;
                }
                & .rating-star{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    overflow: hidden;

                    &::before{
                        content: '\f000 \f000 \f000 \f000 \f000';
                        font-family: IcoFont;
                        color: $primary;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

// Expert Guide 
.expert-guide{
    padding-top: 90px;

    @media #{$tablet-device}{
        padding-top: 70px;
    }
    @media #{$large-mobile}{
        padding-top: 50px;
    }

    & .team-wrapper{
        padding-top: 30px;
    
        @media #{$tablet-device}{
            padding-top: 33px;
        }
        @media #{$large-mobile}{
            padding-top: 10px;
        }
    }
}

// Expert Guide Title
.expert-guide-title{
    @media #{$small-mobile}{
        padding-right: 105px;
    }

    & .title{
        font-size: 50px;
        margin-bottom: 0;
        color: $secondary;

        @media #{$desktop-device, $tablet-device}{
            font-size: 42px;
        }
        @media #{$large-mobile}{
            font-size: 35px;
            margin-top: 20px;
        }
        @media #{$small-mobile}{
            font-size: 24px;
        }
    }
}

    
    
    
    