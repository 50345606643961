/*--
/*  4.3 - Package CSS
/*----------------------------------------*/

// Package Wrapper
.packages-wrapper{
    margin-top: 93px;

    @media #{$large-mobile}{
        margin-top: 35px;
    }
    @media #{$tablet-device}{
        margin-top: 63px;
    }
}

// Single Package 
.single-package{
    position: relative;

    & .packege-image{
        position: relative;

        & img{
            width: 100%;
        }

        &::before{
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(7,28,53,0) 0%, rgba(7,28,53,1) 100%);
            opacity: 0;
            visibility: hidden;
            transition: $transition-base;
        }
    }
    & .packege-content{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 30px;
        opacity: 0;
        visibility: hidden;
        transition: $transition-base;

        @media #{$laptop-device}{
            padding: 15px;
        }
        @media #{$desktop-device, $small-mobile}{
            padding: 20px;
        }

        & .content-meta{
            display: flex;
            justify-content: space-between;
            align-items: center;

            & span{
                font-size: 15px;
                font-weight: 400;
                color: $white;

                @media #{$laptop-device, $small-mobile}{
                    font-size: 13px;
                }
                @media #{$desktop-device}{
                    font-size: 14px;
                }
                & i{
                    color: $primary;
                    margin-right: 2px;

                    @media #{$laptop-device, $desktop-device}{
                        margin-right: 0;
                    }
                }
            }
        }
        & .content-middle{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 12px;

            & .title{
                margin-bottom: 0;

                & a{
                    font-size: 35px;
                    color: $white;
                    transition: $transition-base;

                    @media #{$laptop-device, $desktop-device, $small-mobile}{
                        font-size: 24px;
                    }
                    @media #{$tablet-device}{
                        font-size: 28px;
                    }

                    &:hover{
                        color: $primary;
                    }
                }
            }
            & p{
                font-size: 13px;
                color: $white;
            }
        }
        & .content-btn-price{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 20px;

            & .btn{
                padding: 0 30px;
            }
            & .price{
                font-size: 25px;
                font-weight: 700;
                color: $primary;
            }
        }
    }
    &:hover{
        & .packege-image{
            &::before{
                opacity: 1;
                visibility: visible;
            }
        }
        & .packege-content{
            opacity: 1;
            visibility: visible;
        }
    }
}



  
