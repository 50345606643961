/*--
/*  4.13 - Team CSS
/*----------------------------------------*/

// Team Wrapper
.team-wrapper{
    padding-top: 50px;

    @media #{$tablet-device}{
        padding-top: 33px;
    }
    @media #{$large-mobile}{
        padding-top: 10px;
    }
}


// Single Team 
.single-team{
    position: relative;
    margin-top: 30px;

    & .team-image{
        & img{
            width: 100%;
        }
    }
    & .team-content{
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($secondary, 0.88);
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        padding: 50px 0;
        overflow: hidden;
        transition: $transition-base;
        opacity: 0;
        visibility: hidden;

        & .content-wrapper{
            transform: translateY(-100%);
            opacity: 0;
            visibility: hidden;
            transition: $transition-base;

            & .designation{
                text-transform: uppercase;
                font-size: 13px;
                color: $primary;
                font-weight: 600;
                letter-spacing: 3px;

                @media #{$tablet-device, $large-mobile}{
                    font-size: 11px;
                }
            }
            & .name{
                color: $white;
                font-size: 26px;
                margin-bottom: 0;
                margin-top: 15px;

                @media #{$tablet-device, $large-mobile}{
                    font-size: 22px;
                }
            }
        }
        & .social{
            display: flex;
            justify-content: center;
            transform: translateY(100%);
            opacity: 0;
            visibility: hidden;
            transition: $transition-base;
            
            & li{
                padding: 0 5px;

                & a{
                    width: 40px;
                    height: 40px;
                    border: 1px solid #465669;
                    text-align: center;
                    line-height: 38px;
                    color: $white;
                    font-size: 16px;
                    display: block;
                    transition: $transition-base;

                    &:hover{
                        background-color: $primary;
                        border-color: $primary;
                    }
                }
            }
        }
    }

    &:hover{
        & .team-content{
            opacity: 1;
            visibility: visible;

            & .content-wrapper,
            & .social{
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
            }
        }
    }
}


// Team Active
.team-active{
    position: relative;

    & .swiper-button-next,
    & .swiper-button-prev{
        width: 55px;
        height: 55px;
        line-height: 55px;
        text-align: center;
        font-size: 18px;
        color: $secondary;
        background-color: #f8f8f8;
        border-radius: 50%;
        margin-top: 0;
        top: -60px;
        right: 0;
        outline: none;

        @media #{$small-mobile}{
            width: 45px;
            height: 45px;
            line-height: 45px;
        }

        &::after{
            display: none;
        }
        &:hover{
            background-color: $primary;
            color: $white;
        }
    }
    & .swiper-button-prev{
        left: auto;
        right: 75px;

        @media #{$small-mobile}{
            right: 60px;
        }
    }
}

