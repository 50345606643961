/*--
/*  4.8 - Blog CSS
/*----------------------------------------*/

// Blog Section 
.blog-section{
    background-color: $secondary;
    position: relative;
    overflow: hidden;

    & .shape-1{
        width: 927px;
        height: 927px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        top: -297px;
        left: -387px;
        opacity: 0.06;
    }
    & .shape-2{
        width: 927px;
        height: 927px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        top: -77px;
        left: 10%;
        opacity: 0.06;
    }
    & .shape-3{
        width: 927px;
        height: 927px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        bottom: -207px;
        right: 10%;
        opacity: 0.06;
    }
    & .shape-4{
        width: 927px;
        height: 927px;
        border: 1px solid $white;
        border-radius: 50%;
        position: absolute;
        top: -297px;
        right: -387px;
        opacity: 0.06;
    }
}


// Single Blog  
.blog-wrapper{
    padding-top: 50px;

    @media #{$tablet-device}{
        padding-top: 33px;
    }
    @media #{$large-mobile}{
        padding-top: 10px;
    }
}


// Single Blog  
.single-blog{
    position: relative;
    margin-top: 30px;

    & .blog-image{
        & a{
            display: block;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                background: linear-gradient(180deg, rgba(7,28,53,0) 0%, rgba(7, 28, 53, 0.8) 100%);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            & img{
                width: 100%;
            }
        }
    }
    & .blog-content{
        padding: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @media #{$desktop-device, $large-mobile}{
            padding: 15px;
        }

        & .blog-meta{
            display: flex;
            justify-content: space-between;

            & span{
                font-size: 15px;
                font-weight: 400;
                color: $white;

                & i{
                    margin-right: 5px;
                    color: $primary;
                }
            }
        }
        & .blog-title{
            margin-bottom: 0;

            & a{
                font-size: 32px;
                font-weight: 700;
                color: $white;
                transition: $transition-base;
                line-height: 1.25;
                margin-top: 20px;
                display: block;

                @media #{$desktop-device, $small-mobile}{
                    font-size: 24px;
                }
                @media #{$tablet-device}{
                    font-size: 28px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}


// Single Blog Post
.single-blog-post{
    position: relative;
    margin-top: 50px;

    & .blog-image{
        & a{
            display: block;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                background: linear-gradient(180deg, rgba(7,28,53,0) 0%, rgba(7, 28, 53, 0.6) 100%);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                @media #{$small-mobile}{
                    background: linear-gradient(180deg, rgba(7,28,53,0.1) 0%, rgba(7, 28, 53, 0.8) 100%);
                }
            }

            & img{
                width: 100%;
            }
        }
    }
    & .blog-content{
        padding: 25px 50px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        @media #{$desktop-device}{
            padding: 25px 30px;
        }
        @media #{$large-mobile}{
            padding: 25px;
        }
        @media #{$small-mobile}{
            padding: 15px;
        }

        & .blog-meta{
            display: flex;

            & span{
                font-size: 15px;
                font-weight: 400;
                color: $white;
                margin-right: 35px;
                display: block;

                @media #{$small-mobile}{
                    margin-right: 20px;
                    font-size: 14px;
                }
                
                &:last-child{
                    margin-right: 0;
                }

                & i{
                    margin-right: 5px;
                    color: $primary;
                }
            }
        }
        & .blog-title{
            margin-bottom: 0;

            & a{
                font-size: 38px;
                font-weight: 700;
                color: $white;
                transition: $transition-base;
                line-height: 1.25;
                margin-top: 20px;
                display: block;

                @media #{$desktop-device,$tablet-device }{
                    font-size: 30px;
                }                
                @media #{$large-mobile}{
                    font-size: 24px;
                }

                &:hover{
                    color: $primary;
                }
            }
        }
    }
}










