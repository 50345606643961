/*--
/*  4.5 - Why Choose CSS
/*----------------------------------------*/


// Why Choose Image 
.why-choose-image{
    margin-top: 50px;
    
    & img{
        width: 100%;
    }
}


// Why Choose Contact 
.why-choose-content{
    padding-left: 90px;
    margin-top: 45px;

    @media #{$desktop-device, $tablet-device, $large-mobile}{
        padding-left: 0;
    }
}


// Why Choose Items 
.why-choose-items{
    padding-top: 43px;

    & .single-item{
        display: flex;
        margin-top: 35px;

        @media #{$small-mobile}{
            display: block;
        }

        & .item-icon{
            & img{
                border-radius: 50%;
            }
        }
        & .item-content{
            flex: 1;
            padding-left: 40px;

            @media #{$small-mobile}{
                padding-left: 0;
                padding-top: 25px;
            }

            & .title{
                font-size: 25px;
                font-weight: 600;
                font-family: $font-family-base;
                color: $secondary;
                margin-bottom: 0;

                @media #{$small-mobile}{
                    font-size: 22px;
                }
            }
            & p{
                margin-top: 15px;
            }
        }
    }
}





