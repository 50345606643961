/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/

// Slider Section 
.slider-section{}


// Single Slider 
.single-slider{
    height: 1120px;
    background-size: cover;
    background-position: center center;
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    @media #{$laptop-device}{
        height: 880px;
    }
    @media #{$desktop-device}{
        height: 780px;
    }
    @media #{$tablet-device}{
        height: auto;
        padding-top: 180px;
        padding-bottom: 130px;
        display: block;
    }
    @media #{$large-mobile}{
        height: auto;
        padding-top: 180px;
        display: block;
    }
    @media #{$small-mobile}{
        padding-top: 140px;
    }

    &::before{
        background-color: rgba($secondary, 0.9);
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-top: 110px solid $white;
        border-left: 130px solid $white;
        border-bottom: 130px solid $white;
        opacity: 0.85;

        @media #{$laptop-device}{            
            border-left-width: 70px;
            border-bottom-width: 70px;
        }
        @media #{$desktop-device}{
            border-left-width: 50px;
            border-bottom-width: 50px;
            border-top-width: 98px;
        }
        @media #{$tablet-device}{
            border-left-width: 30px;
            border-bottom-width: 30px;
            border-top-width: 96px;
        }
        @media #{$large-mobile}{
            border-top-width: 90px;
            border-left-width: 30px;
            border-bottom-width: 30px;
        }
        @media #{$small-mobile}{
            border-left-width: 0;
            border-top-width: 76px;
        }
    }
}


// Slider Images 
.slider-images{
    position: absolute;
    width: 50%;
    height: 100%;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    padding-bottom: 130px;

    @media #{$extraBig-device}{
        width: 53%;
    }
    @media #{$laptop-device}{
        padding-bottom: 70px;
    }
    @media #{$desktop-device}{
        padding-bottom: 50px;
    }
    @media #{$tablet-device}{           
        padding-bottom: 30px;
    }
    @media #{$large-mobile}{
        position: relative;
        padding-bottom: 30px;
        text-align: right;
        display: block;
        width: 90%;
        margin-left: auto;
        margin-top: 50px;
    }
}


// Slider Content 
.slider-content{
    
    @media #{$small-mobile}{
        max-width: 350px;
    }

    & .title{
        font-size: 90px;
        color: $white;        
        margin-bottom: 0;

        @media #{$desktop-device}{
            font-size: 70px;
        }
        @media #{$tablet-device, $large-mobile}{
            font-size: 50px;
        }
        @media #{$small-mobile}{
            font-size: 36px;
            line-height: 1.3;
        }
    }
    & p{
        font-size: 18px;
        line-height: 2;
        color: #dbdbdb;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 10px;

        @media #{$desktop-device, $tablet-device, $large-mobile}{
            font-size: 16px;
        }
        @media #{$tablet-device, $small-mobile}{
            & br{
                display: none;
            }
        }
    }
    & .btn{
        margin-top: 40px;

        @media #{$extraBig-device, $laptop-device}{
            height: 75px;
            line-height: 73px;
            padding: 0 45px;
        }

        &::before{
            @media #{$extraBig-device, $laptop-device}{
                width: 300px;
                height: 300px;
            }
        }
    }
}


// Animation Style 
.animation-style-01{

    & .slider-images,
    & .slider-content{
        opacity: 0;
    }

    &.swiper-slide-active{
        & .slider-content{
            opacity: 1;

            & .title{
                animation-name: fadeInUpBig;
                animation-delay: 0.4s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & p{
                animation-name: fadeInUpBig;
                animation-delay: 0.8s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
            & .btn{
                animation-name: fadeInUpBig;
                animation-delay: 1.2s;
                animation-duration: 1s;
                animation-fill-mode: both;
            }
        }

        & .slider-images{
            opacity: 1;

            & img{
                animation-name: fadeInRightBig;
                animation-delay: 1.2s;
                animation-duration: 1.3s;
                animation-fill-mode: both;
            }
        }
    }
}


// Slider Active
.slider-active{
    & .swiper-pagination{
        @media #{$extraBig-device}{
            width: auto;
            top: 50%;
            transform: translateY(-50%);
            bottom: auto;
            left: 60px;
        }
        @media #{$laptop-device}{
            bottom: 20px;
        }
        @media #{$tablet-device, $large-mobile}{
            bottom: 5px;
        }

        & .swiper-pagination-bullet{
            outline: none;            
            background-color: $secondary;
            transition: $transition-base;
            opacity: 1;
            width: 10px;
            height: 10px;

            @media #{$extraBig-device}{
                display: block;
                margin: 10px 0;
            }

            &.swiper-pagination-bullet-active{
                background-color: $primary;
            }
        }
    }
}