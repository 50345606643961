/*--
/*  2.1 - Button CSS
/*----------------------------------------*/

.btn {
    position: relative;
    z-index: 2;
    overflow: hidden;

    @media #{$large-mobile}{
        font-size: 16px;
    }

    &::before{
        width: 200px;
        height: 200px;
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        z-index: -1;
        transform: translate(-50%, -50%) scale(0);
        transition: all 0.3s linear;
        border-radius: 50%;
    }

    & i{
        margin-left: 8px;
    }

    &:hover{
        &::before{
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
@each $name, $value in $theme-colors {  
    .btn-#{$name} {

        @if $name == light {
            color: $dark;
        } @else if $name == warning {
            color: $dark;
        }@else if $name == info {
            color: $dark;
        } @else {
            color: $white;
        }
    } 
    .btn-hover-#{$name} {
        &:hover {
            border-color: $value;

            &::before{
                background-color: $value;
            }            

            @if $name == light {
                color: $dark;
            } @else if $name == warning {
                color: $dark;
            } @else {
                color: $white;
            }
        }
    }
}
