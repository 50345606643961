/*--
/*  4.11 - Mission And Vision CSS
/*----------------------------------------*/


// Mission And Vision Content 
.mission-vision-content{
    background-color: #f8f8f8;
    min-height: 555px;
    margin-top: 30px;
    padding: 60px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;

    @media #{$desktop-device, $small-mobile}{
        padding: 20px;
    }

    & .shape-1{
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        position: absolute;
        right: 40px;
        top: 40px;
        width: 96px;
        height: 96px;
        z-index: -1;
    }
    & .shape-2{
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        position: absolute;
        top: 130px;
        right: 130px;
        width: 29px;
        height: 29px;
        z-index: -1;
    }

    & .content-wrapper{

        & img{
            border-radius: 50%;
            box-shadow: 0px 10px 23px 0px rgba(0, 0, 0, 0.02);
        }
        & .title{
            margin-bottom: 0;
            font-size: 35px;
            color: $secondary;
            margin-top: 32px;

            @media #{$desktop-device, $small-mobile}{
                font-size: 30px;
            }
        }
        & p{
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}


// Mission And Vision image 
.mission-vision-image{
    background-size: cover;
    background-position: center center;
    min-height: 555px;
    margin-top: 30px;
    position: relative;


    & .image-content{
        background-color: $secondary;
        max-width: 145px;
        padding: 5px 30px 30px;
        position: absolute;
        left: 30px;
        bottom: 30px;

        &::before{
            position: absolute;
            content: '';
            top: -65px;
            left: 0;
            width: 0;
            height: 0;
            border-bottom: 65px solid $secondary;
            border-right: 145px solid transparent;
        }
        
        & .number{
            font-size: 20px;
            color: $primary;

            & strong{
                font-size: 40px;
            }
        }
        & p{
            font-size: 18px;
            font-family: $headings-font-family;
            color: $white;
            margin-bottom: 0;
            margin-top: 8px;
        }
    }
    
}
