/*--
/*  5.2 - Sidebar Widget CSS
/*----------------------------------------*/

// Sidebar Widget 
.sidebar-widget{
    margin-top: 48px;
}

// Widget Title 
.widget-title{
    font-size: 35px;
    color: $secondary;
    margin-bottom: 0;

    @media #{$desktop-device, $small-mobile}{
        font-size: 30px;
    }
}

// Widget Search 
.widget-booking-form{
    padding: 50px;
    background-color: #f8f8f8;
    text-align: center;

    @media #{$desktop-device, $small-mobile}{
        padding: 30px;
    }

    & .form-wrapper{
        padding-top: 25px;

        & .single-form{
            position: relative;
            margin-top: 10px;

            & .form-icon{
                position: absolute;
                right: 20px;
                color: #6d7988;
                bottom: 15px;
                font-size: 14px;

                @media #{$large-mobile}{
                    bottom: 14px;
                }
            }

            & input:not([type="checkbox"]):not([type="radio"]){
                height: 50px;
                padding-right: 38px;
            }
            & .gj-datepicker {
                position: relative;
    
                & i{
                    position: absolute;
                    right: 20px;
                    color: #6d7988;
                    bottom: 16px;
                    top: auto;
                    font-size: 14px;
    
                    @media #{$large-mobile}{
                        bottom: 17px;
                    }
                }
            }
        }
    }
}

// Widget Location Map 
.widget-location-map{
    margin-top: 63px;

    & .widget-title{
        position: relative;
        padding-left: 50px;

        &::before{
            position: absolute;
            content: '';
            width: 30px;
            height: 3px;
            background-color: $primary;
            top: 50%;
            left: 0;
        }
    }
    & .location-map-wrapper{
        margin-top: 28px;

        & iframe{
            width: 100%;
            height: 500px;
        }
    }
}

// Widget Search 
.widget-search{
    background-color: #f8f8f8;
    padding: 65px 50px 70px;
    margin-top: 50px;

    @media #{$desktop-device, $small-mobile}{
        padding: 45px 30px 50px;
    }

    & .search-form{
        position: relative;
        margin-top: 38px;

        & input{
            width: 100%;
            height: 50px;
            padding: 0 25px;
            padding-right: 50px;
            border: 0;
            font-size: 16px;
            font-weight: 50;
            color: #6d7988;
            transition: all 0.3s linear;
            border-radius: 0;
            background-color: $white;

            &:focus{
                outline: none;
                border-color: $primary;
            }
            @include placeholder{
                opacity: 1;
            }
        }
        & button{
            position: absolute;
            top: 0px;
            right: 0px;
            border: 0;
            padding: 0;
            width: 50px;
            height: 50px;
            color: $primary;
            text-align: center;
            background: none;
            transition: $transition-base;
        }
    } 
} 

// Widget Link 
.widget-category{
    background-color: #f8f8f8;
    padding: 65px 50px 70px;
    margin-top: 50px;

    @media #{$desktop-device, $small-mobile}{
        padding: 45px 30px 50px;
    }
}

// Widget Link 
.widget-link{
    padding-top: 25px;

    & ul{
        & li{
            position: relative;
            padding-top: 10px;

            & a{
                font-size: 16px;
                font-weight: 400;
                color: $body-color;
                transition: $transition-base;
                display: block;
                height: 50px;
                line-height: 50px;
                padding: 0 20px;
                background-color: $white;

                & i{
                    float: right;
                    display: block;
                    line-height: 50px;
                }

                &:hover{
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}

// Widget Post 
.widget-add{
    margin-top: 50px;

    & a{
        display: block;

        & img{
            width: 100%;
        }
    }
}

// Widget Tags
.widget-tags{
    background-color: #f8f8f8;
    padding: 65px 50px 70px;
    margin-top: 50px;

    @media #{$desktop-device, $small-mobile}{
        padding: 45px 30px 50px;
    }

    & .tags-items{
        display: flex;
        flex-wrap: wrap;
        margin-left: -5px;
        margin-right: -5px;
        padding-top: 18px;

        & li{
            padding: 5px 6px;

            & a{
                height: 45px;
                line-height: 45px;
                padding: 0 25px;
                font-size: 15px;
                background-color: $white;
                display: block;
                color: $body-color;
                transition: $transition-base;

                @media #{$small-mobile}{
                    padding: 0 20px;
                }

                &:hover{
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }
}



// Widget Post 
.widget-post{
    border: 1px solid #a8dadc;
    border-radius: 5px;
    padding: 15px 25px;
    margin-top: 35px;

    & .single-post{
        display: flex;
        padding: 15px 0;

        & + .single-post{
            border-top: 1px solid #a8dadc;
        }

        & .post-thumb{
            & a{
                display: block;

                & img{
                    width: 85px;
                    height: 70px;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }
        & .post-content{
            flex: 1;
            padding-left: 15px;

            & .title{
                margin-bottom: 0;

                & a{
                    font-size: 18px;
                    font-weight: 600;
                    color: $secondary;
                    transition: $transition-base;

                    @media #{$desktop-device, $small-mobile}{
                        font-size: 16px;
                    }

                    &:hover{
                        color: $primary;
                    }
                }
            }
            & .date{
                font-size: 16px;
                color: $body-color;

                @media #{$desktop-device, $small-mobile}{
                    font-size: 14px;
                }
            }
        }
    }
}





// Widget Color 
.widget-color{
    padding: 0 15px;

    & .color-list{
        display: flex;
        flex-wrap: wrap;

        & li{
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
            position: relative;
            border: 1px solid #b8b8b8;
            cursor: pointer;
            margin-top: 5px;

            &:last-child{
                margin-right: 0;
            }

            &::before{
                position: absolute;
                content: '';
                width: 100%;
                height: 100%;
                border: 3px solid $white;
                border-radius: 50%;
                top: 0;
                left: 0;
            }

            &::after{
                content: '\eed6';
                font-family: IcoFont !important;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                text-align: center;
                color: $white;
                opacity: 0;
                visibility: hidden;
            }
            &.active{
                &::after{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

// Widget Banner 
.widget-banner{
    display: block;
    position: relative;

    &::before {
        background:rgba($white,0.2);
        bottom:0;
        content:"";
        left:50%;
        position:absolute;
        right:51%;
        top:0;
        opacity:1
    }
    &::after {
        background:rgba($white,0.2);
        bottom:50%;
        content:"";
        left:0;
        position:absolute;
        right:0;
        top:50%;
        opacity:1
    }

    & img{
        width: 100%;
    }

    &:hover::before {
        left:0;
        right:0;
        opacity:0;
        transition:all 900ms ease-in
    }
    &:hover::after {
        top:0;
        bottom:0;
        opacity:0;
        transition:all 900ms ease-in
    }
}









