/*--
/*  4.7 - Destination CSS
/*----------------------------------------*/


// Destination Section
.destination-section{
    position: relative;
    z-index: 1;

    & .destination-bg{
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: -1;
    }
}


// Choose Destination Content 
.choose-destination-content{
    @media #{$extraBig-device}{
        padding: 108px 0;
    }
    & p{
        margin-top: 20px;
        margin-bottom: 0;
    }
    & .btn{
        margin-top: 41px;

        @media #{$extraBig-device, $laptop-device}{
            height: 65px;
            line-height: 63px;
            padding: 0 45px;

            &::before{
                width: 300px;
                height: 300px;
            }
        }
    }
}


// Choose Destination Content 
.choose-destination-items{
    @media #{$tablet-device, $large-mobile}{
        padding-top: 20px;
    }
    
    & .single-item{
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: center center;
        max-width: 350px;
        padding: 13px 30px;
        position: relative;
        z-index: 1;
        margin-top: 30px;
        margin-left: auto;

        @media #{$tablet-device, $large-mobile}{
            margin-left: 0;
        }
        @media #{$small-mobile}{
            padding: 13px 20px;
        }

        &::before{
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            background-color: rgba($secondary, 0.6);
            z-index: -1;
            transition: $transition-base;
        }

        & .item-flag{
            border-radius: 50%;
            border: 3px solid $white;
        }
        & .item-title{
            flex: 1;
            padding-left: 30px;

            @media #{$small-mobile}{
                padding-left: 20px;
            }

            & .title{
                font-size: 28px;
                color: $white;
                margin-bottom: 0;

                @media #{$small-mobile}{
                    font-size: 24px;
                }
            }
            & i{
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 20px;
                color: $white;
                transition: $transition-base;
                opacity: 0;
                visibility: hidden;
            }
        }
        & a{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
        }

        &:nth-child(2n+1){
            margin-right: 80px;

            @media #{$tablet-device, $large-mobile}{
                margin-left: 80px;
                margin-right: 0;
            }
            @media #{$small-mobile}{
                margin-left: 0;
            }
        }
        &:nth-child(2n+2){}

        &:hover{
            &::before{
                background-color: rgba($primary, 0.8);
            }

            & .item-title{
                & i{
                    opacity: 1;
                    visibility: visible;
                    right: 30px;
                }
            }
        }
    }
}






