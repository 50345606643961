/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/

@import "page/slider";
@import "page/booking-form";
@import "page/package";
@import "page/brand";
@import "page/why-choose";
@import "page/testimonial";
@import "page/destination";
@import "page/blog";
@import "page/newsletter";
@import "page/page-banner";
@import "page/mission-vision";
@import "page/winning-awards";
@import "page/team";
@import "page/packages-details";
@import "page/blog-details";
@import "page/faq";
@import "page/contact";
@import "page/404-error";








