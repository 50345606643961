/*--
/*  2.4 - Search CSS
/*----------------------------------------*/

// Search Box 
.search-box{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba($white, 0.8);    
    display: none;
}

// Search Close 
.search-close{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 10%;
    right: 8%;

    @media #{$large-mobile}{
        right: 12%;
        top: 8%;
    }

    & span{
        width: 20px;
        height: 2px;
        background-color: $dark;
        transform: rotate(-45deg);
        display: block;
        position: relative;

        &:nth-of-type(1){
            transform: rotate(-45deg);
            top: 8px;
        }
        &:nth-of-type(2){
            transform: rotate(45deg);
            top: 6px;
        }
    }
}

// Search Wrapper 
.search-wrapper{
    width: 60%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);

    @media #{$tablet-device, $large-mobile}{
        width: 80%;
    }

    & input{
        width: 100%;
        height: 70px;
        font-size: 20px;
        border: 0;
        background: none;
        border-bottom: 2px solid #d2dfd5;
        padding: 0 20px;
        padding-right: 60px;
        @media #{$large-mobile}{
            font-size: 18px;
            height: 50px;
        }

        &:focus{
            outline: none;
        }
    }
    & button{
        position: absolute;
        font-size: 20px;
        padding: 0;
        height: 70px;
        width: 70px;
        top: 0;
        right: 0;
        border: 0;
        background-color: transparent;

        @media #{$large-mobile}{
            font-size: 18px;
            width: 50px;
            height: 50px;

        }
    }
}














