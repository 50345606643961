/*--
/*  4.4 - Brand CSS
/*----------------------------------------*/


.single-brand{
    border: 1px solid #eeecec;
    text-align: center;
    padding: 30px;
    margin-top: 50px;
    position: relative;

    @media #{$small-mobile}{
        padding: 15px 30px;
    }

    &::before,
    &::after{
        position: absolute;
        content: '';
        top: -1px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 100px;
        height: 1px;
        background-color: $primary;
        transform: scaleX(0);
        transition: $transition-base;

        @media #{$small-mobile}{
            width: 70px;
        }
    }
    &::after{
        top: auto;
        bottom: -1px;
    }

    & .brand-logo{
        overflow: hidden;
        position: relative;

        & img{
            transition: $transition-base;
        }
        & .hover{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translateY(100%);
            transition: $transition-base;
        }
    }
    &:hover{
        & .brand-logo{
            & img{
                transform: translateY(-100%);
            }
            & .hover{
                transform: translateY(0);
            }
        }
        &::before,
        &::after{
            transform: scaleX(1);
        }
    }
}










