/*--
/*  4.15 - Blog Details CSS
/*----------------------------------------*/


// Blog Details Images
.blog-details-images{
    & img{
        width: 100%;
    }
}


// Blog Details Wrapper
.blog-details-wrapper{
    padding-top: 45px;

    & .title{
        font-size: 35px;
        font-weight: 700;
        color: $secondary;
        line-height: 1.25;
        padding-top: 20px;
        margin-bottom: 25px;

        @media #{$large-mobile}{
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 22px;
        }
    }

    & p{
        line-height: 1.8;
    }

    & .details-tags-share{
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        padding-bottom: 15px;
        padding-top: 5px;
        margin-top: 50px;

        @media #{$small-mobile}{
            display: block;
        }

        & .label{
            font-size: 15px;
            font-weight: 600;
            color: $secondary;
            margin-right: 10px;
        }
        & .details-tags{
            display: flex;
            margin-top: 10px;

            & .tags{
                display: flex;

                & li{
                    margin-right: 3px;

                    & a{
                        color: $body-color;
                        font-size: 15px;

                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
        & .details-share{
            display: flex;
            align-items: center;
            margin-top: 10px;

            & .social{
                display: flex;

                & li{                    
                    & + li{
                        margin-left: 10px;
                    }
                    & a{
                        width: 40px;
                        height: 40px;
                        line-height: 38px;
                        text-align: center;
                        border: 1px solid rgba(70, 86, 105, 0.15);
                        font-size: 16px;
                        display: inline-block;
                        color: $secondary;

                        &:hover{
                            color: $primary;
                        }
                    }
                }
            }
        }
    }
}


// Blog Details Meta
.blog-details-meta{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;


    & .meta{
        display: flex;

        & span{
            font-size: 15px;
            font-weight: 400;
            color: $body-color;
            margin-right: 50px;
            display: block;
            margin-bottom: 10px;
            white-space: nowrap;

            @media #{$large-mobile}{
                margin-right: 30px;
            }

            @media #{$small-mobile}{
                margin-right: 20px;
                font-size: 14px;
            }
            
            &:last-child{
                margin-right: 0;
            }

            & i{
                margin-right: 5px;
                color: $primary;
            }
        }
    }
}


// Blog Details comment
.blog-details-comment{
    & .title{
        font-size: 35px;
        font-weight: 700;
        color: $secondary;
        line-height: 1.25;

        @media #{$large-mobile}{
            font-size: 30px;
        }
        @media #{$small-mobile}{
            font-size: 22px;
        }
    }
}


// Details comments
.details-comments{
    padding-top: 40px;

    & .comment-items{
        margin-top: 40px;

        & .comment-reply{
            padding-left: 50px;

            @media #{$small-mobile}{
                padding-left: 0;
            }

            & .comment-reply{
                @media #{$small-mobile}{
                    padding-left: 0;
                }
            }
        }
    }
    & .single-comment{
        background-color: #f8f8f8;
        padding: 35px 50px;
        display: flex;
        margin-top: 20px;

        @media #{$desktop-device, $large-mobile}{
            padding: 25px 30px;
        }
        @media #{$small-mobile}{
            display: block;
        }

        & .comment-thumb{}

        & .comment-content{
            position: relative;
            flex: 1;
            padding-left: 40px;

            @media #{$desktop-device, $large-mobile}{
                padding-left: 30px;
            }
            @media #{$small-mobile}{
                padding-left: 0;
                padding-top: 25px;
            }
            
            & .name{
                font-size: 25px;
                color: $secondary;
                margin-bottom: 0;

                @media #{$large-mobile}{
                    font-size: 20px;
                }
            }
            & .designation{
                color: $primary;
                font-size: 13px;
                display: block;
                margin-top: 5px;                
            }            
            & p{
                margin-bottom: 0;
                margin-top: 10px;
                line-height: 1.8;
            }
            & .reply{
                font-size: 14px;
                font-weight: 600;
                color: $primary;
                position: absolute;
                top: 0;
                right: 0;
                transition: $transition-base;
                display: inline-block;

                @media #{$small-mobile}{
                    position: relative;
                    margin-top: 15px;
                }

                & i{
                    margin-right: 5px;
                }

                &:hover{
                    color: $secondary;
                }
            }
        }
    }
}


// comment Form
.comment-form{
    padding-top: 40px;

    & .form-wrapper{
        & .single-form{
            position: relative;

            & input,
            & textarea{
                background-color: #f8f8f8;
                padding-right: 50px;
            }
            & input{
                height: 60px;
            }

            & .form-icon{
                position: absolute;
                right: 25px;
                top: 18px;
            }

            & .btn{
                padding: 0 45px;
                height: 60px;
                line-height: 60px;

                @media #{$large-mobile}{
                    padding: 0 30px;
                    height: 50px;
                    line-height: 50px;
                }

                &::before{
                    width: 300px;
                    height: 300px;
                }
            }
        }
    }
}











