/*--
/*  4.9 - Newsletter CSS
/*----------------------------------------*/


// Newsletter Section
.newsletter-section{
    position: relative;
    z-index: 1;
    padding-top: 60px;
    padding-bottom: 90px;

    @media #{$tablet-device}{
        padding-top: 40px;
        padding-bottom: 80px;
    }

    @media #{$large-mobile}{
        padding-top: 20px;
        padding-bottom: 60px;
    }

    &::before{
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background-color: rgba($primary, 0.8);
        top: 0;
        left: 0;
        z-index: -1;
    }
}

// Newsletter content 
.newsletter-content{
    margin-top: 40px;

    & .title{
        font-size: 48px;
        font-weight: 700;
        color: $white;

        @media #{$desktop-device}{
            font-size: 38px;
        }

        @media #{$large-mobile}{
            font-size: 44px;
        }
        @media #{$small-mobile}{
            font-size: 34px;
        }
        
    }
}

// Newsletter Form 
.newsletter-form{
    position: relative;
    margin-top: 50px;

    @media #{$extraBig-device}{
        padding-left: 70px;
    }

    & input{
        width: 100%;
        height: 66px;
        padding: 0 25px;
        border-radius: 10px;
        border: 0;
        outline: none;
        padding-right: 72px;
    }
    & button{
        width: 58px;
        height: 58px;
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: $secondary;
        color: $primary;
        font-size: 22px;
        border-radius: 10px;
        border: 0;
        transition: $transition-base;

        &:hover{
            background-color: $primary;
            color: $white;
        }
    }
}




