/*--
/*  4.2 - Booking Form CSS
/*----------------------------------------*/

// Booking Form Top 
.booking-form-top{
    display: flex;
    justify-content: space-between;

    @media #{$large-mobile}{
        display: block;
    }
}

// Active Traveler 
.active-traveler{
    display: flex;
    align-items: center;

    @media #{$large-mobile}{
        padding-top: 25px;
    }
    @media #{$small-mobile}{
        flex-wrap: wrap;
    }
    
    & .title{
        font-size: 16px;
        font-weight: 500;
        color: $secondary;
        display: inline-block;
        white-space: nowrap;
        margin-right: 30px;
    }
    & .traveler-author{
        display: flex;
        
        
        & li{
            border-radius: 50%;

            & + li{
                margin-left: -25px;
            }            
            
            & img{
                border-radius: 50%;
                border: 2px solid $white;
            }
            & span{
                width: 60px;
                height: 60px;
                line-height: 56px;
                text-align: center;
                background-color: $primary;
                border: 2px solid $white;
                color: $white;
                font-size: 14px;
                display: block;
                border-radius: 50%;
            }
        }
    }
}


// Booking Form Wrapper
.booking-form-wraper{
    background-color: #f3f3f3;
    padding: 70px;
    padding-top: 40px;
    margin-top: 45px;

    @media #{$desktop-device}{
        padding: 50px;
        padding-top: 20px;
    }
    @media #{$small-mobile}{
        padding: 30px;
        padding-top: 0;
    }

    & .single-form{
        position: relative;

        & .form-icon{
            position: absolute;
            right: 20px;
            color: #6d7988;
            bottom: 24px;
            font-size: 14px;

            @media #{$large-mobile}{
                bottom: 14px;
            }
        }
        & .btn{
            width: 100%;
            height: 65px;
            line-height: 63px;

            &::before{
                width: 300px;
                height: 300px;

                @media #{$large-mobile}{
                    width: 500px;
                    height: 500px;
                }
            }
            & i{
                position: relative;
                bottom: 0;
            }
        }
        & input:not([type="checkbox"]):not([type="radio"]) + input{
            margin-top: 0;
            padding-right: 36px;
        }

        & .gj-datepicker {
            position: relative;

            & i{
                position: absolute;
                right: 20px;
                color: #6d7988;
                bottom: 26px;
                top: auto;
                font-size: 14px;

                @media #{$large-mobile}{
                    bottom: 17px;
                }
            }
        }
    }
}



.tt-hint {
    color: #999;
}
.tt-dropdown-menu {
    width: 100%;
    margin-top: 7px;
    background: $white;
    border: 1px solid #eeecec;
    max-height: 300px;
    overflow-y: auto;
    white-space: nowrap;
}
.tt-suggestion {
    padding: 10px 20px;
    font-size: 12px;
    border-bottom: 1px solid #eeecec;
}
.tt-suggestion p {
    margin: 0;
}
.tt-suggestion.tt-cursor {
    color: $white;
    background: $primary;
    cursor: pointer;
}


